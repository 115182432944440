import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

export interface RadioButtonListItem {
  display:String,
  isChecked:boolean,
  data?:any,
  isNested:boolean,
  isPrimary:boolean,
  isRollup:boolean
}

@Component({
  selector: "radiobutton-list",
  templateUrl: "./radiobutton-list.component.html",
  styleUrls: ["./radiobutton-list.component.scss"]
})
export class RadioButtonListComponent implements OnInit {

  @Input() items:RadioButtonListItem[];

  @Output() itemChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  handleClick($event,clickedItem){
    this.items.forEach((item)=>{
      let oldState = item.isChecked;
      item.isChecked = item === clickedItem;
      if(oldState!=item.isChecked){
        this.itemChanged.emit(item);
      }
    })

  }

  handlePrimary($event,item){
    $event.stopPropagation();
    item.isPrimary=!item.isPrimary
    this.itemChanged.emit(item);
  }
}
