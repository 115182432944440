import {Component, Input, OnDestroy, OnInit} from "@angular/core";

@Component({
  selector: "esp-multi-select-filter",
  templateUrl: "./multi-select-filter.component.html",
  styleUrls: ["./multi-select-filter.component.scss"]
})
export class MultiSelectFilterComponent implements OnInit,OnDestroy{
  @Input() multiSelect;
  @Input() displaySelectOptions = true;
  subscription = null;

  constructor() {
  }

  ngOnInit(): void {
    if(this.multiSelect.filterValue===undefined){
      this.multiSelect.filterValue = "";
    }

    this.subscription = this.multiSelect.onPanelHide.subscribe(this.onFilterReset.bind(this))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  filterNow() {
    console.log(this.multiSelect);
  }

  onFilterChanged(text) {
      this.multiSelect.filterValue = text;
  }

  onFilterReset() {
      this.multiSelect.filterValue = ""
  }

  onSelectAll(){
    this.multiSelect.selectedOptions = this.multiSelect._options().map(opt => opt.value);
    this.multiSelect.updateModel(this.multiSelect.selectedOptions);
    this.multiSelect.onModelChange(this.multiSelect.value);
    this.multiSelect.onChange.emit({ originalEvent: null, value: this.multiSelect.value });
  }

  onClearAll(){
    this.multiSelect.selectedOptions = [];
    this.multiSelect.updateModel([]);
    this.multiSelect.onModelChange(this.multiSelect.value);
    this.multiSelect.onChange.emit({ originalEvent: null, value: this.multiSelect.value });
  }
}
