import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {map, take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {Actions, ofType} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {currentPlanDetailSelector} from "../../../store";
import {PlanLoadingOverlayComponent} from "../plan-loading-overlay/plan-loading-overlay.component";
import {GridApi} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {DateUtils} from "../../../common/utils/date";
import {DatePipe} from "@angular/common";

import {
  AppActionTypes,
  ApplyStaffingData,
  DiscardMUDist,
  EditMUDistV2,
  GetMuStaffing,
  TaskCancel,
  TaskComplete,
  TaskError
} from "../../../store/actions";

import {ValidationIndicatorCellComponentV2} from "./validation-indicator-cell-v2/validation-indicator-cell-v2.component";
import {WeekHeaderComponentV2} from "./week-header-v2/week-header-v2.component";

import {WfmModalComponent as modalComponent} from "../../../components/wfm-modal/wfm-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NumericEditorComponent} from "../common/cell-editors/numeric/numeric-editor.component";
import {DialogOptions} from "../../../common/dialog-options";
import {MessageService} from "primeng/api";
import {BaseHeaderComponentV2} from "./base-header-v2/base-header-v2.component";
import {discardMuDist, DiscardMuDistRequest, EditMuDistRequestV2, Features} from "src/app/models/plan";
import {BackgroundTask, TaskState} from "../../../store/application-state";
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {PlanHelper} from "../../../helpers/plan-helper";
import {EspColumnHeaderComponent} from "./esp-column-header/esp-column-header.component";

import {ClipboardModule} from "@ag-grid-enterprise/clipboard";
import {EnterpriseCoreModule} from "@ag-grid-enterprise/core";
import {RangeSelectionModule} from "@ag-grid-enterprise/range-selection";
import {CanComponentDeactivate} from "../../../helpers/can-deactivate-guard.service";
import {AuthenticationService} from '../../../authentication.service';
import {DebounceUtil} from '../../../helpers/debounce';
import { TenantService } from "../../../tenant.service";

var dateLocale = DateUtils.getDatefnsLocale("en");


@Component({
  selector: "plan-distribution-v2",
  templateUrl: "./plan-distribution-v2.component.html",
  styleUrls: ["./plan-distribution-v2.component.scss"],
  providers: [MessageService]

})
export class PlanDistributionComponentV2  implements OnInit, OnDestroy {

  constructor(
    private translateSrv: TranslateService,
    private store: Store<any>,
    private action$: Actions,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    public tenantService: TenantService

  ) {
    this.userInfo$ = this.store.select("state");
    this.userInfo$.subscribe((value: any) => {
      if (value && value.user && value.user.language) {
        if (this.language != value.user.language) {
          dateLocale = DateUtils.getDatefnsLocale(value.user.language);
          this.language = value.user.language;
        }
      }
      if (value && value.locale) {
        this.locale = value.locale;
      }
    });

    this.updateGridValid = DebounceUtil.debounce(this.updateGridValid.bind(this),100);

  }

  get getMuList() {
    return this.muList;
  }

  get getRowData() {
    return this.rowData;
  }
  private unsubscribe$: Subject<void> = new Subject();
  private planName: string;
  private rowData = null;
  private colDefs = [];
  private frameworkComponents;
  private gridParams: any;
  public gridApi: GridApi;
  private gridOptions = {};
  private baseColDefs = [];
  public locale = "en-US";
  private planDetails: any;
  private muList = [];
  private selectedMU = null;
  private _isLoading = false;
  private gridContext = null;

  private modules = [ClipboardModule,RangeSelectionModule,EnterpriseCoreModule]
  private editingContext = null;
  public _isGridValid = true;
  private cellClassRules: any;
  public rowClassRules: any;

  public _isPristine = true;
  private userInfo$: any;
  public isReadOnly = false;
  private language: any;
  public task: BackgroundTask;//={status:TaskState.InProgress,progress:50,taskId:1};
  private _isDialogOpen: Boolean;

  public _dirtyColumns = new Set();
  public _invalidColumns = new Set();

  private lastEditedCell:any = null;
  private planDetailsSaved = false;

  ngOnInit(): void {
    this.frameworkComponents = {
      planLoadingOverlay: PlanLoadingOverlayComponent,
      numericEditor: NumericEditorComponent,
      validationIndicatorCell: ValidationIndicatorCellComponentV2,
      weekHeader: WeekHeaderComponentV2,
      baseHeader: BaseHeaderComponentV2,
      ctHeader: EspColumnHeaderComponent
    };

    this.translateSrv
      .get(["plan.dist.mu.column"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.initGridOptions();
        this.initBaseColumns();
        this.initRowClassRules();
        this.initHandlers();
        //this.handlePlanDistResponse();
      });

    this.gridContext = {
      isLoading: this.isLoading.bind(this),
      cellEditable: this.cellEditable.bind(this),
      isGridValid: () => this._isGridValid,
      editingContext: () => this.editingContext,
      columnSum: this.columnSum.bind(this),
      resetColumn: this.resetColumn.bind(this),
      copyBase: this.copyBaseToWeeks.bind(this),
      showMessage: this.showMessage.bind(this),
      isReadOnly:()=>this.isReadOnly,
      isValidColumn:(field)=>{
        return this._invalidColumns.has(field)===false;
      }

    };

    this.planDetailsSaved = history?.state?.saved;
  }

  ngOnDestroy(): void {

    if(this.task){
      this.store.dispatch(new TaskCancel(this.task));
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initGridOptions() {
    this.gridOptions = {
      suppressRowTransform: true,
      suppressPropertyNamesCheck:true,
      defaultColDef: {
        sortable: false,
        resizable: true,
        filter: false,
        width: 110,
      },
      onCellEditingStarted: this.cellEditingStarted.bind(this),
      onCellEditingStopped: this.cellEditingStopped.bind(this),
      onCellValueChanged:this.cellValueChanged.bind(this),
      onPasteEnd:this.onPasteEnd.bind(this),
      processCellForClipboard:this.processCellForClipboard.bind(this),
      processDataFromClipboard:this.processDataFromClipboard.bind(this),
      processCellFromClipboard:this.processCellFromClipboard.bind(this)
    };
  }

  initRowClassRules() {
    this.rowClassRules = {
      'editable-row': (params) => !params.context.isReadOnly(),
      'readonly-row': (params) => params.context.isReadOnly()
    }
  }

  initBaseColumns() {

    this.baseColDefs.push({
      headerName: null,
      headerValueGetter: () => this.translateSrv.instant("plan.dist.ct.column"),
      headerComponent: "ctHeader",
      headerGroupComponentFramework: null,
      field: "ct",
      tooltipField: "ct",
      headerClass: "",
      width: 200,
      lockPosition: true,
      pinned: "left",
      sortable: false
    });
    this.baseColDefs.push({
      headerName: null,
      headerValueGetter: () => this.translateSrv.instant("plan.dist.base.column"),
      headerComponent: "baseHeader",
      headerGroupComponentFramework: null,
      field: "base",
      headerClass: "",
      width: 90,
      pinned: "left",
      lockPosition: true,
      valueGetter: this.cellValueGetter.bind(this),
      valueSetter: this.cellValueSetter.bind(this),
      valueFormatter: this.cellValueFormatter.bind(this),
      cellEditorSelector: this.cellEditor.bind(this),
      cellEditorParams: this.cellEditorParam.bind(this),
      editable: this.cellEditable.bind(this),
      cellRenderer: "validationIndicatorCell",
      cellRendererParams: this.cellRendererParams.bind(this),
      cellClassRules: {

        invalid: (params) => {
          return this._isGridValid == false && this._invalidColumns.has(params.colDef.field);
        },
        locked: (params) => {
          return this._isGridValid == false && this._invalidColumns.has(params.colDef.field)==false;
        },
        "read-only": (params) => {
          return this.isReadOnly;
        },
      }
    });
  }

  initHandlers() {
    this.store
      .pipe(
        select(currentPlanDetailSelector),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.currentPlanChanged.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.PlanDetailReceived),
        map((detail: any) => detail.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.currentPlanChanged.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.PlanDetailReceivedV2),
        map((detail: any) => detail.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.currentPlanChanged.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetMuStaffingSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.MuStaffingReceivedHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetMuStaffingFailed), takeUntil(this.unsubscribe$))
      .subscribe(this.MuStaffingFailedHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.EditMUDistSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.EditMUDistSuccessHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.EditMUDistError), takeUntil(this.unsubscribe$))
      .subscribe(this.EditMUDistFailureHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.DiscardMUDistError), takeUntil(this.unsubscribe$))
      .subscribe(this.discardErrorHandler.bind(this));

    // uses the same callback as the GetMuStaffingSuccess action
    this.action$
      .pipe(ofType(AppActionTypes.DiscardMUDistSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.MuStaffingReceivedHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.ApplyStaffingDataSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.applyStaffingDataSuccessHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.ApplyStaffingDataError), takeUntil(this.unsubscribe$))
      .subscribe(this.applyStaffingDataErrorHandler.bind(this));

    this.action$.pipe(takeUntil(this.unsubscribe$), ofType(
      AppActionTypes.TaskCreateAction,
      AppActionTypes.TaskStartAction,
      AppActionTypes.TaskStatusAction,
      AppActionTypes.TaskErrorAction,
      AppActionTypes.TaskCancelAction,
      AppActionTypes.TaskCompleteAction)
    ).subscribe(this.taskHandler.bind(this))

  }

  private hideOverlay() {
    if (this.gridApi) {
      this.gridApi.hideOverlay();
    }
  }

  private showLoadingOverlay() {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
  }

  MuStaffingFailedHandler() {
    this.rowData = [];
    this.messageService.add({ severity: "error", detail: this.translateSrv.instant("plan.dist.load.failure") });

    this._isLoading = false;
    this.hideOverlay();
    //ignore
  }

  private MuStaffingReceivedHandler(muStaffingData) {
    let response = JSON.parse(JSON.stringify(muStaffingData.payload));
    if(response.mus){
      this.muList = this.getMUFromResponse(response);
    }

    this.isReadOnly = response.readOnly;

    if(response.weekDates){
      Object.keys(response.weekDates).forEach((week) => {
        response.weekDates[week].monthYear= DateUtils.getMonthYear(week,dateLocale);
      });
      let columns = this.getColumnsFromResponse(response);
      this.colDefs = [...this.baseColDefs, ...columns];
    }
    this.rowData = this.getRowsFromResponse(response);
    this._invalidColumns.clear();
    this._dirtyColumns.clear();
    this._isGridValid = true;
    this._isLoading = false;
    this.hideOverlay();
  }

  private setGridLoading(p:boolean){
    this._isLoading = p;
    p ? this.showLoadingOverlay() : this.hideOverlay();
  }

  private EditMUDistSuccessHandler(actionObj: any){
    this._dirtyColumns.clear();
    this._invalidColumns.clear();

    switch (actionObj.payload.request.editAction) {
      case EditAction.COPY_BASE:
        let matchingKeys: any;
        const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}/;
        matchingKeys = Object.keys(this.rowData[0]).filter((key) => pattern.test(key));
        this.rowData.forEach((row) => {
          matchingKeys.forEach(k => delete row[k]);
        });
        break;
      case EditAction.EDIT_BASE:

        break;
      case EditAction.EDIT_WEEK:
        break;
      case EditAction.REVERT_TO_BASE:
        let field = actionObj.payload.request.distributionDates[0]
        this.resetToBase(field);
        break;
      default:
        break;
    }

    this.updateGridValid();
    this.setGridLoading(false);
    this.lastEditedCell = null;
    this.editingContext = null;
    this._isPristine=false;
  }

  private EditMUDistFailureHandler(actionObj: any){

    /*switch (actionObj.payload.request.editAction) {
      case EditAction.COPY_BASE:
        this.messageService.add({ severity: "error", detail: this.translateSrv.instant("plan.dist.copy.base.failure") });
        break;
      case EditAction.EDIT_BASE:
      case EditAction.EDIT_WEEK:
        this.messageService.add({ severity: "error", detail: this.translateSrv.instant("plan.dist.edit.failure") });
        const editedCell = actionObj.payload.request.lastEditedCell
        this.rowData.forEach(row=>{
          if(row.ctObj.oid === editedCell.ctObj.oid){
            row[editedCell.field] = editedCell.oldVal;
          }
        });
        this.validateSingleColumn(editedCell.field);
        break;
      case EditAction.REVERT_TO_BASE:
        this.messageService.add({ severity: "error", detail: this.translateSrv.instant("plan.dist.revert.failure") });
        break;
      default:
        break;
    }*/
    this.messageService.add({ severity: "error", detail: this.translateSrv.instant("plan.dist.edit.failure") });
    this.setGridLoading(false);
    this.updateGridValid();

    this.updateMuStaffing();

  }

  currentPlanChanged(planDetails) {
    // console.debug("PlanDistributionComponent currentPlanChanged");
    if (planDetails) {
      this.planName = planDetails.planName;
      this.planDetails = planDetails;
      this.hideOverlay();
      // console.debug(planDetails);
      // debugger;
      this.updateMuStaffing();
      //this.handlePlanDistResponse();
    }
  }

  updateMuStaffing() {
    this._isLoading = true;
    this.showLoadingOverlay();
    this.store.dispatch(new GetMuStaffing({
      planId: this.planDetails.planId,
      mu: this.selectedMU ? this.selectedMU.value : null
    }));
  }

  onGridReady(params: any) {
    this.gridParams = params;
    this.gridApi = params.api;
  }

  private getColumnsFromResponse(response: any) {
    //Group by month and year
    let datesByMonthYear = {};
    Object.keys(response.weekDates).forEach((week) => {
      let monthYear = response.weekDates[week].monthYear;
      datesByMonthYear[monthYear] = [...(datesByMonthYear[monthYear] || []), response.weekDates[week]];
    });

    return Object.keys(datesByMonthYear).map((monthYear) => {
      return {
        headerName: monthYear,
        headerNameFull: monthYear,
        headerClass: "groupedColumnHeader",
        width: null,
        children: this.getChildColumns(datesByMonthYear[monthYear]),
        lockPosition:true
      };
    });
  }

  private getChildColumns(datesByMonthYearElement) {
    return datesByMonthYearElement.map((week, index) => {
      return {
        field: week.startDate,
        startDate: week.startDate,
        endDate: week.endDate,
        headerName:
          DateUtils.getShortMonthYear(this.datePipe, week.startDate, this.locale) +
          "-" +
          DateUtils.getShortMonthYear(this.datePipe, week.endDate, this.locale),
        headerNameFull:
          DateUtils.getShortDate(this.datePipe, week.startDate, this.locale) +
          "-" +
          DateUtils.getShortDate(this.datePipe, week.endDate, this.locale),
        headerComponent: "weekHeader",
        suppressSizeToFit: true,
        width: 114,
        headerClass: "no-bold no-icon no-black " + (index === 0 ? "first-item" : ""),
        //cellEditorSelector: this.cellEditor,
        //editable: this.cellEditable,
        valueGetter: this.cellValueGetter.bind(this),
        valueSetter: this.cellValueSetter.bind(this),
        valueFormatter: this.cellValueFormatter.bind(this),
        cellEditorSelector: this.cellEditor.bind(this),
        cellEditorParams: this.cellEditorParam.bind(this),
        editable: this.cellEditable.bind(this),
        cellRenderer: "validationIndicatorCell",
        cellRendererParams: this.cellRendererParams.bind(this),
        lockPosition:true,
        //cellEditorParams: this.cellEditorParam,
        //onCellValueChanged: this.cellValueChanged,
        //validator: ValidatorFactory.getValidator,
        //valueSetter: this.cellValueSetter.bind(this),
        //cellClass: this.cellClass.bind(this)
        cellClassRules: {
          "calculated-field": (params) => {
            return this.weekValueGetter(params.data, params.colDef.field) === null || this.isReadOnly;
          },
          invalid: (params) => {
            return this._isGridValid == false && this._invalidColumns.has(params.colDef.field);
          },
          locked: (params) => {
            return this._isGridValid == false && this._invalidColumns.has(params.colDef.field)==false;
          },
          "read-only": (params) => {
            return this.isReadOnly;
          },
        }
      };
    });
  }

  public getRowsFromResponse(response: any) {
    if(this.tenantService.isCXOne){
      return this.getRowsFromResponseCXOne(response);
    }

    return response.muStaffingData.cts.map((ct, index) => {
      let row = {
        mu: response.muStaffingData.mu.name,
        ct: ct.ct.id + " " + ct.ct.name,
        ctObj: ct.ct,
        base: ct.baseDistribution,
        metadata: {
          validator: this.getValidator(),
          decimal: 2
        }
      };
      Object.keys(ct.dateDistributions).forEach((date) => {
        row[date] = ct.dateDistributions[date];
      });
      return row;
    });
  }

  public getRowsFromResponseCXOne(response: any) {
    return response.muStaffingData.cts.map(ct => {
      let row = {
        mu: response.muStaffingData.mu.name,
        ct: ct.ct.name,
        ctObj: ct.ct,
        base: ct.baseDistribution,
        metadata: {
          validator: this.getValidator(),
          decimal: 2
        }
      };
      Object.keys(ct.dateDistributions).forEach((date) => {
        row[date] = ct.dateDistributions[date];
      });
      return row;
    });
  }

  weekValueGetter(row, column) {
    let value = row[column];
    if (value === undefined) {
      value = null;
    }
    return value;
  }

  weekValueGetterOrBase(row, column) {
    let value = row[column];
    if (value === undefined || value === null) {
      value = row.base;
    }
    return value;
  }

  cellValueGetter(cell) {
    let value = this.weekValueGetterOrBase(cell.data, cell.colDef.field);

    if (value !== null && value !== "" && !isNaN(value)) {
      return parseFloat(value);
    }else{

    }
    return value;
  }

  cellValueFormatter(cell) {
    let value = this.weekValueGetterOrBase(cell.data, cell.colDef.field);

    return Number(value).toLocaleString(this.locale, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + "%";
  }

  cellValueSetter(params) {
    const decimal = 2;
    let newVal = params.newValue ? parseFloat(this.floor(params.newValue, decimal)) : parseFloat(params.newValue);
    let oldVal = params.oldValue ? parseFloat(this.floor(params.oldValue, decimal)) : parseFloat(params.oldValue);

    const data = params.data;

    if (newVal !== oldVal) {
      newVal = isNaN(newVal) ? 0: newVal
      data[params.colDef.field] = newVal;
      this.setBaseValue(params.colDef.field);
      this.lastEditedCell = {...data, oldVal, newVal, field: params.colDef.field};
      return true;
    }
    return false;
  }

  dispatchEditRequest(editActionParm: EditAction){
    const editRequestV2:EditMuDistRequestV2 = {
      planId: this.planDetails.planId,
      muOid: this.selectedMU.object.oid,
      base:{},
      applyBase:false,
      dateDistributions:{},
      applyBaseToDates:[],
      editAction:editActionParm
    }

    //if it's a copy base to all weeks, we don't care about the week columns
    if(editActionParm === EditAction.COPY_BASE){
      editRequestV2.base = this.CTValues("base");
      editRequestV2.applyBase = true;
    }else{
      this._dirtyColumns.forEach((dirtyColumn:string)=>{
        if(dirtyColumn==="base"){
          editRequestV2.base = this.CTValues(dirtyColumn);
        }else{
          if(this.isColumnEqualsBase(dirtyColumn)){
            editRequestV2.applyBase=true;
            editRequestV2.applyBaseToDates.push(dirtyColumn);
          }else{
            editRequestV2.dateDistributions[dirtyColumn] = this.CTValues(dirtyColumn);
          }
        }
      });
    }

    this.setGridLoading(true);
    this.store.dispatch(new EditMUDistV2(editRequestV2));
    this._dirtyColumns.clear();
  }

  cellEditor(params: any) {
    var editor = {component: null, param: params};
    editor.component = "numericEditor";

    return editor;
  }

  cellEditable(params: any) {
    if(this.isReadOnly){
      return false;
    }

    /*if (!this.editingContext)
      return true;
    */
    if(this._isGridValid){
      return true;
    }

    return this._invalidColumns.has(params.column.colId);

  }

  cellRendererParams(params: any) {
    var param = {
      origValue: params.data[params.colDef.field],
      metadata: params.data.metadata,
      locale: this.locale
    };
    //gets added to the editor parameter
    return param;
  }
  cellEditorParam(params: any) {
    var param = {
      origValue: params.data[params.colDef.field],
      metadata: params.data.metadata,
      zeroOnEmpty:true,
      locale: this.locale
    };
    //gets added to the editor parameter
    return param;
  }
  cellEditingStarted(params: any) {
    this.editingContext = params;
  }

  cellEditingStopped(params: any) {
    /*this.updateGridValid();
    if(this._isGridValid
        && this.lastEditedCell
        && this.lastEditedCell.oldVal !== this.lastEditedCell.newVal){
      const editAction = this.lastEditedCell.field === "base" ? EditAction.EDIT_BASE: EditAction.EDIT_WEEK
      this.dispatchEditRequest(editAction, this.lastEditedCell.field);
    }

    if(!this.lastEditedCell){ //nothing was edited because it was the same value
      this.editingContext=null;
    }

    this.refreshCells();
    let currentFocusedCell = this.gridApi.getFocusedCell();
    if (currentFocusedCell && !this._isDialogOpen) {
      this.gridApi.setFocusedCell(currentFocusedCell.rowIndex, currentFocusedCell.column.getColId(), null);
    }
*/
    this._isPristine = false;

  }

  refreshCells() {
    if (this.gridApi) {
      this.gridApi.refreshCells({force: true});
    }
  }

  getValidator() {
    return {
      isValid: (value: any) => {
        if(value===null || value==="")
          return true;

        if(isNaN(value)){
          return false;
        }
        value = parseFloat(value);
        return value == null || ((value >= 0) && (value <= 100));
      },
      msg : "validation.err.dist_value",
      title : "validation.err.title"
    };
  }

  updateSelectedMU(mu) {
    this.selectedMU = mu;
    this.gridApi.clearRangeSelection();
    this.updateMuStaffing();
  }

  isLoading(): boolean {
    return this._isLoading;
  }

  updateGridValid() {
    let result = true;
    this._dirtyColumns.forEach((field:string)=>{
      let isValidColumn = this.validateSingleColumn(field);
      result = result && isValidColumn;
    });

    this._isGridValid = result;

    //if we are ready to save, dispatch the message
    if(this._invalidColumns.size===0 && this._dirtyColumns.size>0){
      this.dispatchEditRequest(EditAction.EDIT_WEEK);
    }
    this.refreshCells();
  }


  validateSingleColumn(field: string){
    let sum = this.columnSum(field);
    let valid = sum == 100 || sum == 0;

    if(valid) {
      this._invalidColumns.delete(field);
    }else{
      this._invalidColumns.add(field);
    }

    return valid;
  }

  private getMUFromResponse(response: any) {
    let muList = response.mus.map((mu) => {
      return {
        label: this.getMULabel(mu),
        value: mu.oid,
        object: mu,
      };
    });
    this.selectedMU = muList.find((mu) => mu.value == response.muStaffingData.mu.oid);
    return muList;
  }

  getMULabel(mu) {
    const isCXone = this.tenantService.isCXOne;
    return isCXone ? `${mu.name}` : `${mu.id} ${mu.name}`;
  }

  public floor(num: any, dec: any):string {
    if (num && num.toString().includes(".")) {
      let array = num.toString().split(".");
      let decPart = array.pop();
      if (!decPart) {
        decPart = "0";
      }
      array.push(decPart.substring(0, dec));
      let result = array.join(".");
      return result;
    } else {
      return num.toString();
    }
  }

  private setBaseValue(field: any) {
    if (field == "base")
      return;

    this.rowData.forEach((row) => {
      if (row[field] === undefined) {
        row[field] = row.base;
      }
    });
  }

  private async resetColumn(column) {
    this.resetToBase(column.colDef.field);
  }

  private resetToBase(field:string) {
    this.rowData.forEach((row) => {
      delete row[field];
    });
    this._dirtyColumns.add(field);

    this.updateGridValid();
    if (this.editingContext) {
      this.editingContext = null;
    }
    this.lastEditedCell = null;
  }

  copyBaseToWeeks(){
    let matchingKeys: any;

    const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}/;
    matchingKeys = Object.keys(this.rowData[0]).filter((key) => pattern.test(key));
    this.rowData.forEach((row) => {
      matchingKeys.forEach(k => delete row[k]);
    });

    this.dispatchEditRequest(EditAction.COPY_BASE)

  }

  private shouldApply(){
    return this._isPristine==false
  }
  private applyChanges(){
    let payload = {planId: this.planDetails.planId}
    this.store.dispatch(new ApplyStaffingData(payload));
  }
  /**
   * Have ngrx store dispatch the HTTP request to revert
   * staffing changes.
   */
  private dispatchDiscardRequest() {
    let body = new DiscardMuDistRequest();
    body.planId = this.planDetails.planId;
    body.revertAll = true;
    body.revertAction = discardMuDist.REVERT_DISTRIBUTIONS;
    body.muOid = this.selectedMU.object.oid;

    this.store.dispatch(new DiscardMUDist(body));
  }

  /**
   * Display the revert staffing changes dialog
   */
  showDiscardDlg() {
    if (!this.shouldApply()) {
      return;
    }

    // revert staffing changes dialog options
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.dist.discard.dlg.title";
    dgOption.messageKey = "plan.dist.discard.dlg.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";

    // returns a Promise
    let result = modalComponent.showModalMessage(dgOption, this.modalService);
    result.then(
      () => {
        this._isPristine = true; // staffing grid no longer dirty
        this.showLoadingOverlay();
        this.dispatchDiscardRequest();
      },
      () => {
        // user changed his mind
        console.debug("user chose not to discard changes")
      }
    );
  }
  async showMessage(titleKey, msgKey, type) {

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = titleKey;
    dgOption.messageKey = msgKey;
    dgOption.msgType = type;
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    this._isDialogOpen=true;
    try{
      const dialog = await modalComponent.showModalMessage(dgOption, this.modalService);
      return dialog;
    }catch(e){

    }finally{
      this._isDialogOpen=false;
    }

  }
  /**
   * Callback to handle failed HTTP call to discard staffing updates
   *
   * @param actionObj - ngrx Action object
   */
  private discardErrorHandler(actionObj: any) {
    this._isPristine = false;
    this.hideOverlay();
    this.messageService.add({
      severity: "error",
      detail: this.translateSrv.instant("plan.dist.discard.error.msg")
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  canCloseTab($event: any) {
    if(this.shouldApply()){
      $event.returnValue=true;
    }
  }

  /**
   * Route guard to control leaving this component
   */
  async canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
                      nextState: RouterStateSnapshot) {
    // let plan details parent page handle prompting for unsaved changes in place of unapplied changes (if any)
    if (this.isLeavingPlanDetailsWithUnsavedChanges(nextState)) {
      return true;
    }

    // allow navigation away if grid is pristine
    if (!this.shouldApply()) {
      return true;
    }

    // Unapplied changes dialog setup
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.exit.unapplied.dialog.title";
    dgOption.messageKey = "plan.exit.unapplied.dialog.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";

    try {
      await modalComponent.showModalMessage(dgOption, this.modalService);
      // allow user to leave staffing grid
      return true;
    } catch(e) {
      console.debug("user chose not to leave staffing grid");
      return false;
    }
  }

  private isLeavingPlanDetailsWithUnsavedChanges(nextState: RouterStateSnapshot): boolean {
    if (this.planDetailsSaved === false && !nextState?.url?.includes("site-plans")) {
      return true;
    }

    return false;
  }

  private taskHandler(taskAction:any) {
    // console.log("Handler: ", taskAction);
    if (taskAction instanceof TaskComplete) {
      this.task = null;
    } else if(taskAction instanceof  TaskCancel) {
      this.task = null;
    }else if(taskAction instanceof TaskError){
      this.task = null;
    } else {
      this.task = taskAction.payload;
    }
    //setTimeout(()=>{
    //  this.store.dispatch(new TaskCancel(this.task));
    //},3000);
  }
  private async applyStaffingDataSuccessHandler(){
    this._isPristine=true;
    this.planDetailsSaved = false;
    let successMsg = this.translateSrv.instant("plan.dist.apply.success");
    this.messageService.add({ severity: "success", detail: successMsg});
  }

  private async applyStaffingDataErrorHandler(e){
    // debugger;
    this.task=null;
    if(e.taskStatus==TaskState.Ready){
      this.messageService.add({ severity: "error", detail: this.translateSrv.instant("plan.dist.apply.error.init") });
    }else{
      this.retryApplying();
    }
  }
  async retryApplying() {
    // Unapplied changes dialog setup
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.dist.apply.error.inprogress.title";
    dgOption.messageKey = "plan.dist.apply.error.inprogress.body";
    dgOption.msgType = "warn";
    dgOption.showCancel = false;
    dgOption.confirmLabel = "plan.dist.apply.error.inprogress.reload";
    //dgOption.cancelLabel = ""

    try {
      this._isPristine=true;
      await modalComponent.showModalMessage(dgOption, this.modalService);
      PlanHelper.removePlanDetailSessionId(this.planDetails.planId);
      await this.router.navigate([".."],{relativeTo: this.route});
    } catch(e) {

    }
  }
  cellValueChanged(cellInfo){
    this._dirtyColumns.add(cellInfo.colDef.field);
    this._isPristine = false;
    this.updateGridValid();
  }
  onPasteEnd(){

  }
  processCellForClipboard(cellInfo){
    return this.weekValueGetterOrBase(cellInfo.node.data,cellInfo.column.colDef.field);
  }
  isColumnEqualsBase(field){
    let result = false;
    if (!this.rowData) {
      return false;
    }
    this.rowData.forEach((row) => {
      let value = this.weekValueGetter(row, field)
      if(value===null) {
        result=true;
      }
    });
    return result;
  }



  CTValues(field){
    const ctDistPercentage:any = {};
    const distPerMap:Map<string,number> = new Map(this.rowData.map((data:any)=> [data.ctObj.oid, data[field]]));
    distPerMap.forEach((k,v)=>ctDistPercentage[v] = k);
    return ctDistPercentage;
  }
  columnSum(field){
    let sum = 0;
    try {
      if (!this.rowData) {
        return 0;
      }
      this.rowData.forEach((row) => {
        let value = this.weekValueGetter(row, field)
        if(value===null){
          sum += 0;
        }else{
          sum += parseFloat(value);
        }
      });
      sum = parseFloat(sum.toFixed(2));
    }catch(e){

    }
    return sum;
  }
  processDataFromClipboard(params){
    //console.log(params);
    let data = params.data;
    let lastRow = data[data.length-1];
    if(lastRow.length===1 && lastRow[0]===""){ //ms excel adds a new row with empty cell on windows
      data.pop();                           //which we need to remove before the cell parsing
    }
    return data;
  }
  processCellFromClipboard(params){
    //console.log(params);
    return params.value;
  }
}

export enum EditAction {
  COPY_BASE, EDIT_WEEK, EDIT_BASE, REVERT_TO_BASE
};
